import React from "react";

function ListControls(props) {

  function cycleLeft(e) {
    props.cycleLists("left");
  }

  function cycleRight(e) {
    props.cycleLists("right");
  }

  return (
    <div className="flex justify-center text-3xl">
      <div className="button cursor-pointer" onClick={cycleLeft}>⏪</div>
      <div className="button cursor-pointer" onClick={props.addList}>📝</div>
      <div className="button cursor-pointer" onClick={cycleRight}>⏩</div>
    </div>
  );
}

export default ListControls;
