import React, { useState, useEffect } from "react";
import NoteTitle from "./NoteTitle";
import NoteForm from "./NoteForm";
import NoteList from "./NoteList";
import ListControls from "./ListControls";
import "./index.css";

function NoteWidget() {
  const [lists, setLists] = useState(() => {
    const saved = localStorage.getItem("lists");
    const savedLists = saved ? JSON.parse(saved) : null;
    return savedLists || [{ title: "A New List", notes: [] }];
  });

  useEffect(() => {
    localStorage.setItem("lists", JSON.stringify(lists));
  }, [lists]);

  function addNote(text, status = 0) {
    const newNotes = [
      ...lists[0].notes,
      { text: text, status: status, createdAt: Date.now() },
    ];
    setLists([{ title: lists[0].title, notes: newNotes }, ...lists.slice(1)]);
  }

  function deleteNote(n) {
    const newNotes = [...lists[0].notes];
    newNotes.splice(n, 1);
    setLists([{ title: lists[0].title, notes: newNotes }, ...lists.slice(1)]);
  }

  function editNote(n, note) {
    const newNotes = [...lists[0].notes];

    if (note.text && newNotes[n].text !== note.text) {
      newNotes[n].text = note.text;
      newNotes[n].modifiedAt = Date.now();
    }

    if (note.status != null) {
      newNotes[n].status = note.status;
      newNotes[n].modifiedAt = Date.now();
    }

    setLists([{ title: lists[0].title, notes: newNotes }, ...lists.slice(1)]);
  }

  function editTitle(newTitle) {
    setLists([{ ...lists[0], ...{ title: newTitle } }, ...lists.slice(1)]);
  }

  function cycleLists(dir="left") {
    if (dir === "left") {
      setLists([...lists.slice(1), lists[0]]);
    } else {
      setLists([lists[lists.length -1], ...lists.slice(0, -1)]);
    }
  }

  function addList() {
    setLists([{title: "A New List", notes: []}, ...lists]);
  }

  return (
    <div
      className="NoteWidget bg-base-200 card
         card-bordered max-w-screen-sm m-6"
    >
      <ListControls cycleLists={cycleLists} addList={addList} />
      <div className="card-body">
        <NoteTitle title={lists[0].title} editTitle={editTitle} />
        <NoteList
          notes={lists[0].notes}
          deleteNote={deleteNote}
          editNote={editNote}
        />
        <NoteForm addNote={addNote} />
      </div>
    </div>
  );
}

export default NoteWidget;
