import React, { useState } from "react";
import "./index.css";

function NoteForm(props) {
  let [formValue, setFormValue] = useState("");

  function handleChange(ev) {
    setFormValue(ev.target.value);
  }

  function handleSubmit(ev) {
    props.addNote(formValue);
    setFormValue("");
    ev.preventDefault();
  }

  return (
    <div>
      <div className="divider" />
      <form onSubmit={handleSubmit} className="flex flex-row space-x-4">
        <input
          type="text"
          value={formValue}
          onChange={handleChange}
          className="input input-bordered grow"
          placeholder="New note"
        />
        <input type="submit" value="➕" className="btn" />
      </form>
    </div>
  );
}

export default NoteForm;
