import React from "react";
import "./index.css";

function NoteList(props) {
  // TODO: Make enter key finish editing
  // TODO: checked status should strikethrough text;
  //       maybe other effects for other states

  const states = ["🔲", "✅", "🚧", "🌟"];
  const statusClasses = ["todo-note", "checked-note",
                         "stalled-note", "important-note"];
  const deleteIcons = ["❎", "❌"];
  const noteClass = "note-div flex flex-row rounded-full";

  function handleDelete(e) {
    const icon = e.target.textContent;

    if (icon === deleteIcons[0]) {
      e.target.textContent = deleteIcons[1];
      setTimeout(() => {
        e.target.textContent = deleteIcons[0];
      }, 750);
    } else {
      setTimeout(() => {
        e.target.parentNode.className = noteClass;
        props.deleteNote(e.target.id);
      }, 400);
      e.target.parentNode.className += " deleted-note";
    }
  }

  const noteList = props.notes.map((note, index) => (
    <div key={index} className={noteClass}>
      <div
        id={index}
        className={"cursor-pointer " + statusClasses[note.status]}
        onClick={(e) => {
          const newStatus = (note.status + 1) % states.length;
          props.editNote(e.target.id, {status: newStatus});
          e.target.className = "cursor-pointer " + statusClasses[newStatus];
        }}
      >
        {states[note.status]}
      </div>
      <div
        id={index}
        className="note-span grow px-4"
        contentEditable="true"
        suppressContentEditableWarning={true}
        onBlur={(e) =>
          props.editNote(e.target.id, { text: e.target.textContent })
        }
      >
        {note.text}
      </div>
      <div id={index} onClick={handleDelete} className="cursor-pointer">
        {deleteIcons[0]}
      </div>
    </div>
  ));

  return <div className="note-list flex flex-col space-y-2">{noteList}</div>;
}

export default NoteList;
