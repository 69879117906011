import React from "react";

function NoteTitle(props) {
  return (
    <div
      className="card-title center-text"
      contentEditable="true"
      suppressContentEditableWarning={true}
      onBlur={(e) => props.editTitle(e.target.textContent)}
    >
      {props.title}
    </div>
  );
}

export default NoteTitle;
